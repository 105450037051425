<template>
  <b-col
    class="hp-image-row"
    cols="12"
    lg="6"
    :style="{
      'background-image':
        'url(' +
        require('@/assets/img/pages/authentication/rsotista.png') +
        ')',
    }"
  >
  </b-col>
</template>

<script>
import { BCol } from "bootstrap-vue";

export default {
  components: {
    BCol,
  },
};
</script>

<style scoped>
.hp-image-row {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

/* mobile screen */
@media (max-width: 575.98px) {
  .hp-image-row {
    display: none;
  }
}

/* tablet screen */
@media (min-width: 576px) and (max-width: 991.98px) {
  .hp-image-row {
    display: none;
  }
}
</style>
